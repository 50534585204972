import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import swirlBottom from "../images/swirl_bottom.svg"
import backArrow from "../images/back_arrow.svg"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const avatar = this.props.data.markdownRemark.frontmatter.avatar
    const author = this.props.data.markdownRemark.frontmatter.author
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div class="blogHeaderImageCont">
          <div class="blogHeaderImageWrapper">
            <Img class="blogHeaderImage" sizes={post.frontmatter.featuredImage.childImageSharp.sizes} style={{minHeight: '300px'}} />
          </div>

          <img class="swirlBottom" src={swirlBottom} alt="swirlBottom"/>
          <Img 
            fixed={avatar.childImageSharp.sizes} 
            style={{
              position: "absolute", 
              top: "100%",
              transform: "translateY(-100%)", 
              left: "10%",
              height: "20vw",
              width: "20vw",
              maxHeight: "250px",
              maxWidth: "250px",
              minHeight: "110px",
              minWidth: "110px",
              borderRadius: "50%",
              zIndex: "30"
            }}
          />
          <Link class="allPostsLink" to={`/blog`}><img src={backArrow} alt="backArrow"/><span>All posts</span></Link>
        </div>

        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article class="section">
          <header class="blogHeader">
            <p class="author">{author}</p>
            <span class="separatorDot"/>
            <p class="date">{post.frontmatter.date}</p>
          </header>
          <h1 class="blogTitle">{post.frontmatter.title}</h1>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1300) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        avatar {
          childImageSharp {
            sizes(maxWidth: 1300) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }  
`
